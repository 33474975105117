body {
	background-color: $primary-background;
	color: rgba(255, 255, 255, 0.9);
	font-family: arial, "Microsoft JhengHei", "微軟正黑體", sans-serif !important;
	font-size: 1em;
	max-width: 1920px;
	margin: 0 auto;
	min-width: 360px;
}

a:hover {
	color: $primary;
}

ul {
	li {
		list-style: none;
	}

	a {
		color: #CFCFCF;
	}
}

select {
	background: rgba(15, 15, 15, 0);
	color: #fafafa;
	position: relative;
	border: $primary 1px solid;
	width: 100%;
	max-width: 276px;
	padding: 8px 16px 8px 8px;
	border-radius: 5px;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	margin-right: 24px;
	background-position: right 0.5em top 50%, 0 0;
	background-size: 16px;
	background-repeat: no-repeat, repeat;
	cursor: pointer;

	option {
		background: rgba(0, 0, 0, 0.885);
	}
}


.invalid-feedback {
	color: $invalid;
	margin-bottom: 1.2rem;
}

.valid-feedback {
	color: $valid;
	margin-bottom: 1.2rem;
}

.btn-outline-warning {
	color: $primary;
	border-color: $primary;

	&:hover {
		background-color: $primary;
		border-color: $primary;
	}
}

.color-primary {
	color: $primary;
}

.bg-1st {
	background-color: $primary-background;
}

.bg-2nd {
	background-color: $secondary-background;
}

.iconImg {
	width: 1.3rem;
}

.btn_primary {
	border: none;
	background-color: $primary;
	padding: 7px 15px;
	color: rgba(15, 15, 15, 1);
	border-radius: 4px;
	border: 1px solid $primary;

	&:hover {
		background-color: transparent !important;
		color: $primary;
		border: 1px solid $primary !important;
	}

	&:focus {
		background-color: transparent;
		color: $primary;
		box-shadow: 0 0 0 .25rem rgba(255, 193, 7, .5);
	}
}

.text-color {
	color: $textColor;
}

.error-Msg {
	color: $invalid;
}