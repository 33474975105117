$primary: #E7C673;
$primary-dark: #8D7129;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$invalid: #fd8686;
$valid: #3ced9c;
$yellow-box-shadow: 0 0 0 .25rem rgba(255, 193, 7, .5);
$transparent-background: rgba(55, 55, 55, 0.9);
$primary-background: #0F0F0F;
$secondary-background: rgba(108, 112, 112, 0.21);
$textColor: rgba(255, 255, 255, 0.9)