.headerContainer {
	background-color: $primary-background;
	z-index: 9999;

	@media screen and (max-width: 768px) {
		padding-right: 0px;
	}
}

.logo {
	cursor: pointer;
	width: 14%;

	img {
		width: 100%;
	}

	@media screen and (max-width: 768px) {
		width: 32%;
	}
}

.menuWrap {
	display: flex;
	padding: 0px;
	margin: 0px;

	li {
		padding: 0px 5px;
	}

	@media screen and (max-width: 768px) {
		display: none;
	}

	.active {
		color: $primary;
	}
}

.loginNav {
	display: flex;
	align-items: center;

	.navLink {
		.bi-person-circle {
			font-size: 1.3rem;

			@media screen and (max-width: 768px) {
				font-size: 1.2rem;
			}
		}
	}

	@media screen and (max-width: 768px) {
		.memberName {
			display: none;
			// 	font-size: .9rem;
		}
	}
}

.navbar-hamburger {
	width: 30px;

	img {
		width: 100%;
	}
}

.hamburger-menu {
	img {
		width: 100%;
	}

	background-color: $primary-background;
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
}

.hamburger-menu-logo {
	max-width: 162px;
}

.hamburger-menu-close {
	max-width: 20px;
}

.hamburger-menu-link {
	ul li {
		padding-left: 0;
		margin-left: 0;
	}

	margin-top: 32px;

	& p:hover,
	& p.active {
		color: $primary;
	}
}