.ticketGenre table {
  thead {
    border-bottom: 1px solid rgba(80, 80, 81, 1);
  }

  thead tr th {
    font-weight: 400;
    font-size: .8rem;
  }
}

#selectNumber {
  border: 1px solid $textColor;
  color: $textColor;
  border-radius: 3px;
  font-size: .8rem;
  background: #232424;
  padding: 6px 8px;
  width: 28%;

  @media screen and (max-width: 768px) {
    font-size: .7rem;
    padding: 5px 7px;
    width: 45%;

  }
}