.footer {
	background-color: rgba(108, 112, 112, 0.21);
	padding-top: 19px;
	padding-bottom: 17px;

	ul {
		padding: 0;
		margin-left: 0;
	}

	hr {
		margin: 25px 0;
	}

	img {
		width: 100%;
	}

	&-logo {
		max-width: 224px;
	}

	&-fb,
	&-ig {
		width: 26px;
	}

	&-content {
		font-size: 20px;
		margin-top: 30px;

		li+li {
			margin-left: 40px;
		}
	}

	&-contact {
		margin-top: 12px;
		font-size: 16px;
		color: #CFCFCF;

		li {
			line-height: 32px;
			height: 32px;
		}
	}

	&-copyright {
		font-size: 16px;
		color: #9E9FA1;
	}

	@media screen and (max-width: 992px) {
		padding-top: 19px;
		padding-bottom: 17px;
		font-size: 16px;

		&-content {
			font-size: 16px;
			margin-top: 22px;
		}

		&-contact {
			margin-top: 12px;
			font-size: 16px;

			li {
				line-height: 32px;
				height: 32px;
			}
		}

		&-copyright {
			font-size: 16px;
		}
	}
}